body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 *
 * DatePicker Custom Style Overrides
 *
 **/
.react-datepicker {
  font-family: 'AltirCommons' !important;
  color: #12285E !important;
  border: none !important;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1) !important;
  border-radius: 16px !important;
  padding: 24px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
}

h2.react-datepicker__current-month {
  padding-bottom: 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.react-datepicker__header {
  background-color: #ffff !important;
  border-bottom: none !important;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
  padding-top: 8px !important;
  padding-bottom: 4px !important;
}

.react-datepicker__navigation-icon {
  top: 8px !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  &:hover {
    background-color: #2B44A4 !important;
    color: #fff !important
  }
}

