@font-face {
    font-family: "AltirCommons";
    src: url("../fonts/Landform-Thin.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "AltirCommons";
    src: url("../fonts/Landform-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "AltirCommons";
    src: url("../fonts/Landform-Regular.otf") format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "AltirCommons";
    src: url("../fonts/Landform-Bold.otf") format("opentype");
    font-weight: bold;
}